import request from "./axios.js";


/**
 * 新闻查询
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getNewsData(data) {
  return request.get("/api/news/",{params:data} );
}

/**
 * 新闻添加
 *
 * @param {Object} data - 对象数据
 */
export function addNewsData(data) {
  return request.post("/api/news", data);
}

/**
 * 新闻查询id
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getNewsDataId(data,id) {
  return request.get("/api/news/"+id,{params:data} );
}

/**
 * 新闻删除
 *
 * @param {Object} data - 对象数据
 */
export function deleteNewsDataId(data,id) {
  return request.delete("/api/news/"+id,{params: data});
}


/**
 * 新闻修改
 *
 * @param {Object} data - 对象数据
 */
export function editNewsDataId(data,id) {
  return request.put("/api/news/"+id,data);
}