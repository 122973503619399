<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick" style="font-size: 12px;" class="rlt-title">
      <!-- <el-tab-pane :label="$t('title1')" name="2"></el-tab-pane> -->
      <el-tab-pane :label="$t('title2')" name="3"></el-tab-pane>
      <el-tab-pane :label="$t('title3')" name="0"></el-tab-pane>
      <el-tab-pane :label="$t('title7')" name="4"></el-tab-pane>
      <!-- <el-tab-pane :label="$t('title4')" name="1"></el-tab-pane> -->
      <el-tab-pane :label="$t('title8')" name="5"></el-tab-pane>
    </el-tabs>
    <div class="guiji">
      <!-- <div id="container" style="width: 70%; padding: 20px 0 20px 20px"></div> -->
      <!-- <div style="width: 70%; height: 100%" id="googlemap"></div> -->
      <div style="width: 100%; padding: 20px 20px;" id="googlemap" v-show="actColor == 1"></div>
      <!-- <div
        style="width: calc(100% - 40px); padding: 0 0 20px 20px"
        id="mapContainer"
        v-show="actColor<2 || actColor == 3"
      >
      </div> -->
      <!-- <div v-show="!show" class="date1">
        <div id="toggle" @click="show=!show">
          <img
            style="margin-top: 8px; width: 25px; height: 25px"
            src="./../../assets/img/toggle.png"
            alt=""
          />
        </div>
      </div> -->
      <div style="width: 70%;background: #000B3F;display: flex;align-items: center;justify-content: center;"
        v-show="actColor == 2 && !filter">
        <div id="map" style="width: 66vw; height: 87vh"></div>
      </div>
      <div style="width: 70%;background: #000B3F;display: flex;align-items: center;justify-content: center;"
        v-show="actColor == 2 && filter">
        <div id="filterTrackMap" style="width: 66vw; height: 87vh"></div>
      </div>
      <div style="width: 70%;background: #000B3F;display: flex;align-items: center;justify-content: center;"
        v-show="actColor == 3">
        <div id="scooterMap" style="width: 66vw; height: 87vh"></div>
      </div>
      <div style="width: 70%;background: #000B3F;display: flex;align-items: center;justify-content: center;"
        v-show="actColor == 4">
        <div id="SwapFrequencyMap" style="width: 66vw; height: 87vh"></div>
      </div>
      <div style="width: 70%;background: #000B3F;display: flex;align-items: center;justify-content: center;"
        v-show="actColor == 0">
        <div id="stationMap" style="width: 66vw; height: 87vh"></div>
      </div>
      <div class="guiji-right" v-show="actColor == 2" style="float: right;">
        <div class="inner-container">
          <div class="guiji-right-top" style="display: flex;justify-content: space-between;">
            {{ $t("hotLang.name") }}
            <el-switch v-model="filter" active-text="过滤Grad Wheels客户" :inactive-text="$t('showAll')">
            </el-switch>
          </div>
          <!-- <div class="guiji-right-top">{{ $t("hotLang.name") }}</div> -->
          <div class="guiji-right-bottom">
            <div class="guiji-right-content" style="background-color: #09254f">
              <div class="guiji-right-item">{{ $t("hotLang.ranking") }}</div>
              <div class="guiji-right-location">{{ $t("hotLang.city") }}</div>
              <div class="guiji-right-item">{{ $t("hotLang.frequency") }}</div>
            </div>
            <div v-show="!filter">
              <div v-for="(item, index) in hotData" :key="index">
                <div class="guiji-right-content" :class="color[index]">
                  <div class="guiji-right-item bg">
                    <span>{{ index + 1 }}</span>
                  </div>
                  <div class="guiji-right-location">{{ item.city }},{{ item.province }}</div>
                  <div class="guiji-right-item">{{ Thousands(item.count) }}</div>
                </div>
              </div>
            </div>
            <div v-show="filter">
              <div v-for="(item, index) in hotDataFilter" :key="index">
                <div class="guiji-right-content" :class="color[index]">
                  <div class="guiji-right-item bg">
                    <span>{{ index + 1 }}</span>
                  </div>
                  <div class="guiji-right-location">{{ item.city }},{{ item.province }}</div>
                  <div class="guiji-right-item">{{ Thousands(item.count) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="guiji-right" v-show="actColor == 3">
        <div class="inner-container">
          <div class="guiji-right-top" style="display: flex;justify-content: space-between;">
            {{ $t("title5") }}
            <el-switch v-model="scooterRankValue" :active-text="$t('showAll')">
            </el-switch>
          </div>
          <div class="guiji-right-bottom">
            <div class="guiji-right-content" style="background-color: #09254f">
              <div class="guiji-right-item">{{ $t("hotLang.ranking") }}</div>
              <div class="guiji-right-location">{{ $t("hotLang.city") }}</div>
              <div class="guiji-right-item">{{ $t("vehicle") }}</div>
            </div>
            <div v-for="(item, index) in scooterRankList" :key="index">
              <div class="guiji-right-content" :class="color[index]" v-if="index < 20 && !scooterRankValue">
                <div class="guiji-right-item bg">
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="guiji-right-location">{{ item.item }}</div>
                <div class="guiji-right-item">{{ Thousands(item.count) }}</div>
              </div>
              <div class="guiji-right-content" :class="color[index]" v-if="scooterRankValue">
                <div class="guiji-right-item bg">
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="guiji-right-location">{{ item.item }}</div>
                <div class="guiji-right-item">{{ Thousands(item.count) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="guiji-right" v-show="actColor == 4">
        <div class="inner-container">
          <div class="guiji-right-top" style="display: flex;justify-content: space-between;">
            {{ $t("title7") }}
            <el-switch v-model="stationmapValue" :active-text="$t('showAll')">
            </el-switch>
          </div>
          <div class="guiji-right-bottom">
            <div class="guiji-right-content" style="background-color: #09254f">
              <div class="guiji-right-item">{{ $t("hotLang.ranking") }}</div>
              <div class="guiji-right-location">{{ $t("hotLang.city") }}</div>
              <div class="guiji-right-item">{{ $t("hotLang.frequency") }}</div>
            </div>
            <div v-for="(item, index) in stationmapList" :key="index">
              <div class="guiji-right-content" :class="color[index]" v-if="index < 20 && !stationmapValue">
                <div class="guiji-right-item bg">
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="guiji-right-location">{{ item.item }}</div>
                <div class="guiji-right-item">{{ Thousands(item.count) }}</div>
              </div>
              <div class="guiji-right-content" :class="color[index]" v-if="stationmapValue">
                <div class="guiji-right-item bg">
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="guiji-right-location">{{ item.item }}</div>
                <div class="guiji-right-item">{{ Thousands(item.count) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="guiji-right" v-show="actColor == 0">
        <div class="inner-container">
          <div class="guiji-right-top" style="display: flex;justify-content: space-between;">
            {{ $t("title6") }}
            <el-switch v-model="scooterRankValue" :active-text="$t('showAll')">
            </el-switch>
          </div>
          <div class="guiji-right-bottom">
            <div class="guiji-right-content" style="background-color: #09254f">
              <div class="guiji-right-item">{{ $t("hotLang.ranking") }}</div>
              <div class="guiji-right-location">{{ $t("hotLang.city") }}</div>
              <div class="guiji-right-item">{{ $t("piece") }}</div>
            </div>
            <div v-for="(item, index) in stationRankList" :key="index">
              <div class="guiji-right-content" :class="color[index]" v-if="index < 20 && !scooterRankValue">
                <div class="guiji-right-item bg">
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="guiji-right-location">{{ item.item }}</div>
                <div class="guiji-right-item">{{ Thousands(item.count) }}</div>
              </div>
              <div class="guiji-right-content" :class="color[index]" v-if="scooterRankValue">
                <div class="guiji-right-item bg">
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="guiji-right-location">{{ item.item }}</div>
                <div class="guiji-right-item">{{ Thousands(item.count) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%;display: flex;justify-content: space-between;" v-show="actColor == 5">
        <div class="guiji-right" style="float: left;width: 30%;height: 100%;">
          <div class="inner-container">
            <div class="guiji-right-top" style="display: flex;justify-content: space-between;">
              {{ $t("hotLang.provinceTitle") }}
            </div>
            <div class="guiji-right-bottom">
              <div class="guiji-right-content" style="background-color: #09254f">
                <div class="guiji-right-item">{{ $t("hotLang.ranking") }}</div>
                <div class="guiji-right-location">{{ $t("hotLang.province") }}</div>
                <div class="guiji-right-item">{{ $t("hotLang.frequency") }}</div>
              </div>
              <div>
                <div v-for="(item, index) in provinceTopList" :key="index">
                  <div class="guiji-right-content" :class="color[index]">
                    <div class="guiji-right-item bg">
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div class="guiji-right-location">{{ item.province }}</div>
                    <div class="guiji-right-item">{{ Thousands(item.num) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="guiji-right" style="float: left;width: 30%;height: 100%;">
          <div class="inner-container">
            <div class="guiji-right-top" style="display: flex;justify-content: space-between;">
              {{ $t("hotLang.cityTitle") }}
            </div>
            <div class="guiji-right-bottom">
              <div class="guiji-right-content" style="background-color: #09254f">
                <div class="guiji-right-item">{{ $t("hotLang.ranking") }}</div>
                <div class="guiji-right-location">{{ $t("hotLang.city") }}</div>
                <div class="guiji-right-item">{{ $t("hotLang.frequency") }}</div>
              </div>
              <div>
                <div v-for="(item, index) in cityTopList" :key="index">
                  <div class="guiji-right-content" :class="color[index]">
                    <div class="guiji-right-item bg">
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div class="guiji-right-location">{{ item.province }},{{ item.city }}</div>
                    <div class="guiji-right-item">{{ Thousands(item.num) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="guiji-right" style="float: left;width: 30%;height: 100%;">
          <div class="inner-container">
            <div class="guiji-right-top" style="display: flex;justify-content: space-between;">
              {{ $t("hotLang.pointsTitle") }}
            </div>
            <div class="guiji-right-bottom">
              <div class="guiji-right-content" style="background-color: #09254f">
                <div class="guiji-right-item">{{ $t("hotLang.ranking") }}</div>
                <div class="guiji-right-location">{{ $t("hotLang.Points") }}</div>
                <div class="guiji-right-item">{{ $t("hotLang.frequency") }}</div>
              </div>
              <div>
                <div v-for="(item, index) in zoneTopList" :key="index">
                  <div class="guiji-right-content" :class="color[index]">
                    <div class="guiji-right-item bg">
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div class="guiji-right-location">{{ item.province }},{{ item.city }},{{ item.zone }}</div>
                    <div class="guiji-right-item">{{ Thousands(item.num) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getNewsData,
} from "../../request/news.js";
import {
  getRoute,
  getShooter,
  getHeatmapRank,
  getPointLocation,
  getHeatmapProvince,
  getScooterdetail,
  getScooterRank,
  getStationRank,
  getStationmap,
  getHistoryStationmapTop
} from "../../request/conlog.js";
// import foowwLocalStorage from "../../utils/foow.js";
// import echartsIndonesia from "./../../utils/Indonesia.js";
// import E from "wangeditor";
// require("echarts/extension/bmap/bmap");
// require("../../utils/Indonesia.js");
// const CUSTOM_MAP_CONFIG = require("../../static/custom_map_config.json");
// import GoogleMapsLoader from "google-maps";
import geobuf from 'geobuf';
import Pbf from 'pbf';
import M from "minimatch";
export default {
  name: "index",
  components: {},
  data() {
    return {
      map: null,
      hotData: [],
      heatmapProviderData: [],
      heatmapProvider: undefined,
      color: ["color1", "color2", "color3"],
      show: true,
      dateShow: false,
      options: [
        {
          value: "2022-04",
          label: "2022-04",
        },
        {
          value: "2022-03",
          label: "2022-03",
        },
        {
          value: "2022-02",
          label: "2022-02",
        },
        {
          value: "2022-01",
          label: "2022-01",
        },
        {
          value: "2021-12",
          label: "2021-12",
        },
        {
          value: "2021-11",
          label: "2021-11",
        },
        {
          value: "2021-10",
          label: "2021-10",
        },
        {
          value: "2021-09",
          label: "2021-09",
        },
        {
          value: "2021-08",
          label: "2021-08",
        },
      ],
      value: "2022-04",
      toggleFlag: true,
      topData: [],
      botData: [],
      stationLocationLayer: undefined,
      scooterLocationLayer: undefined,
      shooterDataLayer: undefined,
      stationData: undefined,
      scooterData: undefined,
      actColor: 3,
      activeName: '3',
      scooterMarkers: [],
      flag: 1,
      marker: undefined,
      info: undefined,
      platform: undefined,
      stationMarkers: [],
      zoneNameMax: 0,
      onlineScooterMarkersLayer: undefined,
      offlineScooterMarkersLayer: undefined,
      offlineStationMarkersLayer: undefined,
      onlineStationMarkersLayer: undefined,
      heatmap: undefined,
      markerCluster: undefined,
      markersScooter: undefined,
      markersStation: undefined,
      scooterRankList: [],
      scooterRankLength: 20,
      scooterRankValue: false,
      stationmapValue: false,
      stationRankList: [],
      hotDataFilter: [],
      stationmapList: [],
      filter: false,
      zoneNameFilterMax: 0,
      provinceTopList: [],
      cityTopList: [],
      zoneTopList: [],
      indoJson: undefined,
    };
  },
  async created() {
    // this.formdata.token = await foowwLocalStorage.get("token").token;
    // this.token = await foowwLocalStorage.get("token").token;
    // this.getData();
    let _this = this;
    window.dateAct = _this.dateAct;
  },
  mounted() {
    let that = this;
    $.get('./Indo_Districts.geojson', function (indoJson) {
      that.indoJson = indoJson
      getHeatmapProvince({
        type: 0
      }).then(res => {
        console.log(res.data.list)
        let list = [];
        let zoneNameMax = 0;
        res.data.list.forEach(i => {
          if (zoneNameMax < parseInt(i.count)) {
            zoneNameMax = i.count
          }
          // console.log(zoneNameMax)
          let item = {
            name: i.city,
            value: parseInt(i.count)
          }
          list.push(item)
        })
        that.zoneNameMax = parseInt(zoneNameMax)
        // console.log(this.zoneNameMax)
        that.make_map("印度尼西亚, 印尼", "map", list, that.zoneNameMax);
      })
      getHeatmapProvince({
        type: 1
      }).then(res => {
        console.log(res.data.list)
        let list = [];
        let zoneNameMax = 0;
        res.data.list.forEach(i => {
          if (zoneNameMax < parseInt(i.count)) {
            zoneNameMax = i.count
          }
          // console.log(zoneNameMax)
          let item = {
            name: i.city,
            value: parseInt(i.count)
          }
          list.push(item)
        })
        that.zoneNameFilterMax = parseInt(zoneNameMax)
        // console.log(this.zoneNameMax)
        that.make_map("印度尼西亚, 印尼", "filterTrackMap", list, that.zoneNameFilterMax);
      })
      getScooterRank().then(res => {
        const list = res.data.list;
        console.log(list)
        let rankList = []
        let max = 0
        list.forEach((item) => {
          if (max < parseInt(item.num)) {
            max = item.num
          }
          that.scooterRankList.push({
            count: item.num,
            item: item.city + ',' + item.province
          })
          let el = {
            name: item.city,
            value: item.num
          }
          rankList.push(el)
        })
        that.make_map("印度尼西亚, 印尼", "scooterMap", rankList, max);
        // this.scooterRankList = this.scooterRankList.slice(0, 20);
      })
      getStationRank().then(res => {
        const list = res.data.list;
        console.log(list)
        let rankList = []
        let max = 0
        list.forEach((item) => {
          if (max < parseInt(item.num)) {
            max = item.num
          }
          that.stationRankList.push({
            count: item.num,
            item: item.city + ',' + item.province
          })
          let el = {
            name: item.city,
            value: item.num
          }
          rankList.push(el)
        })
        that.make_map("印度尼西亚, 印尼", "stationMap", rankList, max);
        // this.scooterRankList = this.scooterRankList.slice(0, 20);
      })
      getStationmap().then(res => {
        const list = res.data.list;
        console.log(list)
        let rankList = []
        let max = 0
        list.forEach((item) => {
          if (max < parseInt(item.num)) {
            max = item.num
          }
          that.stationmapList.push({
            count: item.num,
            item: item.city + ',' + item.province
          })
          let el = {
            name: item.city,
            value: item.num
          }
          rankList.push(el)
        })
        // console.log(rankList)
        that.make_map("印度尼西亚, 印尼", "SwapFrequencyMap", rankList, max);
        // this.scooterRankList = this.scooterRankList.slice(0, 20);
      })

    })

    // this.make_map("印度尼西亚, 印尼", "map", []);
    // this.setGoogleData();
    // let that = this;
    //here地图创建
    // var platform = new H.service.Platform({
    //   apikey: "DAoN89zyIIZgfglerE8XZSgL2-oDO-sAhlKYKSxx6LE",
    // });
    // this.platform = platform;
    // // Obtain the default map types from the platform object
    // var maptypes = platform.createDefaultLayers();
    // // Instantiate (and display) a map object:
    // var map = new H.Map(
    //   document.getElementById("mapContainer"),
    //   maptypes.vector.normal.map,
    //   {
    //     zoom: 12,
    //     center: { lng: 106.82721, lat: -6.17576 },
    //   }
    // );
    // var mapEvents = new H.mapevents.MapEvents(map);
    // var behavior = new H.mapevents.Behavior(mapEvents);
    // var ui = H.ui.UI.createDefault(map, maptypes);
    // this.mapsFullScreenControl(ui);
    // // ui.removeControl('zoom')
    // ui.removeControl("mapsettings");
    // ui.removeControl("scalebar");
    // var heatmapProvider = new H.data.heatmap.Provider({
    //   opacity: 1,
    //   tileSize: 256,
    // });
    // Promise.all([
    //   this.getRouteData(1),
    //   // this.getShooterData(1),
    //   // this.getShooterData(2),
    //   // this.getShooterData(3),
    //   // this.getShooterData(4),
    //   // this.getShooterData(5),
    // ])
    // .then(() => {
    //   // heatmapProvider.addData(this.heatmapProviderData, true);
    //   // this.shooterDataLayer = new H.map.layer.TileLayer(heatmapProvider)
    //   // map.addLayer(this.shooterDataLayer);
    //   this.setGoogleData(this.heatmapProviderData);
    // });
    getHeatmapRank({
      type: 0
    }).then((res) => {
      this.hotData = res.data.list.slice(0, 20);
      console.log(this.hotData)
    });
    getHeatmapRank({
      type: 1
    }).then((res) => {
      this.hotDataFilter = res.data.list.slice(0, 20);
      console.log(this.hotDataFilter)
    });
    // getStationmapTop({ type: 1 }).then((res) => {
    //   this.topData = res.data.list;
    // });
    // getStationmapTop({ type: 2 }).then((res) => {
    //   this.botData = res.data.list;
    // });

    getHistoryStationmapTop().then((res) => {
      // console.log(res)
      const data = res.data;
      this.provinceTopList = data.province
      this.cityTopList = data.city
      this.zoneTopList = data.zone
    })
    // getStationMapData().then(res=>{
    //   console.log(res);
    //   let stationData = [];
    //   const offlineStation = res.data.offlineStation;
    //   const onlineStation = res.data.onlineStation;
    //   offlineStation.forEach(station=>{
    //     stationData.push({ lat: station.latitude, lng: station.longitude });
    //   })
    //   onlineStation.forEach(station=>{
    //     stationData.push({ lat: station.latitude, lng: station.longitude });
    //   })
    //   this.stationData = stationData
    //   // heatmapProvider.addData(stationData, true);
    //   // this.stationLocationLayer = new H.map.layer.TileLayer(heatmapProvider)
    //   var hicon = new H.map.Icon("https://i.postimg.cc/kXrzJJW4/3-88.png", {
    //     size: { w: 36, h: 65 },
    //   });
    //   stationData.forEach((item, index) => {
    //     if (
    //       item.lat !== "" &&
    //       item.lat !== "0" &&
    //       item.lng !== "" &&
    //       item.lng !== "0"
    //     ) {
    //       var marker = new H.map.Marker(
    //         {
    //           lat: parseFloat(item.lat),
    //           lng: parseFloat(item.lng),
    //         },
    //         {
    //           icon: hicon,
    //           // min: 13,
    //         }
    //       );
    //       this.stationMarkers.push(marker);
    //       // this.map.addObject(marker);
    //     } else {
    //       console.log(item);
    //     }
    //   })
    //   this.offlineStationMarkersLayer = this.startClustering(
    //     this.map,
    //     offlineStation
    //   );
    //   // console.log(this.scooterMarkersLayer)
    //   this.onlineStationMarkersLayer = this.startClustering(
    //     this.map,
    //     onlineStation
    //   );
    // })
    // getScooterMapData().then(res=>{
    //   console.log(res);
    //   let scooterData = [];
    //   const offlineScooter = res.data.offlineScooter;
    //   const onlineScooter = res.data.onlineScooter;
    //   offlineScooter.forEach(scooter=>{
    //     scooterData.push({ lat: scooter.latitude, lng: scooter.longitude, code: scooter.scooterCode });
    //   })
    //   onlineScooter.forEach(scooter=>{
    //     scooterData.push({ lat: scooter.latitude, lng: scooter.longitude, code: scooter.scooterCode });
    //   })
    //   this.scooterData = scooterData
    //   // heatmapProvider.addData(stationData, true);
    //   // this.stationLocationLayer = new H.map.layer.TileLayer(heatmapProvider)
    //   var hicon = new H.map.Icon("https://i.postimg.cc/KvBXBkmB/3-63.png", {
    //     size: { w: 36, h: 65 },
    //   });
    //   this.scooterData.forEach((item, index) => {
    //     // console.log(item)
    //     if (
    //       item.lat !== "" &&
    //       item.lat !== "0" &&
    //       item.lng !== "" &&
    //       item.lng !== "0"
    //     ) {
    //       var marker = new H.map.Marker(
    //         {
    //           lat: parseFloat(item.lat),
    //           lng: parseFloat(item.lng),
    //         },
    //         {
    //           icon: hicon,
    //           // min: 13,
    //           data: {
    //             scooterCode: item.code,
    //             lat: parseFloat(item.lat),
    //             lng: parseFloat(item.lng),
    //           },
    //         }
    //       );
    //       marker.addEventListener("tap", this.onMarkerClick);
    //       this.scooterMarkers.push(marker);
    //       // this.map.addObject(marker);
    //     } else {
    //       console.log(item);
    //     }
    //   })
    //   this.offlineScooterMarkersLayer = this.startClustering(
    //       this.map,
    //       offlineScooter
    //     );
    //   // console.log(this.scooterMarkersLayer)
    //   this.onlineScooterMarkersLayer = this.startClustering(
    //     this.map,
    //     onlineScooter
    //   );
    // })
    // this.heatmapProvider = heatmapProvider;
    // this.map = map;
  },
  computed: {},
  methods: {
    //千分数字处理
    Thousands(num) {
      var result = [],
        counter = 0;
      num = (num || 0).toString().split("");
      for (var i = num.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(num[i]);
        if (!(counter % 3) && i != 0) {
          result.unshift(",");
        }
      }
      return result.join("");
    },
    getMarkerDate() {
      let that = this;
      let markerCluster = new markerClusterer.MarkerClusterer({ map: this.map, markers: [] });
      this.markerCluster = markerCluster
      // getScooterMapData().then((res) => {
      //   if (res.errcode === 200) {
      //     let markersScooter = []
      //     const data = res.data;
      //     const offlineScooter = data.offlineScooter;
      //     const onlineScooter = data.onlineScooter;
      //     // this.offlineScooter = offlineScooter
      //     // this.onlineScooter = onlineScooter
      //     //创建谷歌地图图标
      //     let icon = {
      //       url:'https://i.postimg.cc/KvBXBkmB/3-63.png',
      //       // url:require('./../../assets/img/dcar3.png'),
      //       scaledSize: new google.maps.Size(27, 49), // scaled size
      //       origin: new google.maps.Point(0,0), // origin
      //       anchor: new google.maps.Point(0, 0) // anchor
      //     }

      //     offlineScooter.forEach((item, index) => {
      //       //谷歌地图添加图标添加点击事件
      //       const marker = new google.maps.Marker({
      //         position: new google.maps.LatLng(parseFloat(item.latitude),parseFloat(item.longitude)),
      //         icon: icon,
      //         map: null,
      //         title: item.scooterCode,
      //         // optimized: false,
      //         animation: null,
      //       });
      //       marker.addListener('click', function(){
      //         that.getScooterdetailInfo(marker.getTitle(), {lat: parseFloat(item.latitude),lng: parseFloat(item.longitude)}, marker)
      //       })
      //       markersScooter.push(marker)
      //     });
      //     onlineScooter.forEach((item, index) => {
      //       //谷歌地图添加图标添加点击事件
      //       const marker = new google.maps.Marker({
      //         position: new google.maps.LatLng(parseFloat(item.latitude),parseFloat(item.longitude)),
      //         icon: icon,
      //         map: null,
      //         title: item.scooterCode,
      //         // optimized: false,
      //         animation: null,
      //       });
      //       marker.addListener('click', function(){
      //         that.getScooterdetailInfo(marker.getTitle(), {lat: parseFloat(item.latitude),lng: parseFloat(item.longitude)}, marker)
      //       })
      //       markersScooter.push(marker)
      //     });
      //     // markerCluster.addMarkers(markersScooter, true);
      //     this.markersScooter = markersScooter;
      //   }
      // });
      //换电柜
      getPointLocation().then((res) => {
        if (res.errcode === 200) {
          let markersStation = []
          const data = res.data;
          const offlinePoint = data.offlinePoint;
          const onlinePoint = data.onlinePoint;
          //创建谷歌地图图标
          let icon = {
            // url: 'https://i.postimg.cc/KvBXBkmB/3-63.png',
            url: require('./../../assets/img/hElement.png'),
            scaledSize: new google.maps.Size(29, 65), // scaled size
            // origin: new google.maps.Point(0,0), // origin
            // anchor: new google.maps.Point(0, 0) // anchor
          }
          offlinePoint.forEach((item, index) => {
            //谷歌地图添加图标添加点击事件
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(parseFloat(item.latitude), parseFloat(item.longitude)),
              icon: icon,
              map: null,
              title: item.stationCode,
            });
            // marker.addListener('click', function(){
            //   that.getScooterdetailInfo(marker.getTitle())
            // })
            markersStation.push(marker)
          });
          onlinePoint.forEach((item, index) => {
            //谷歌地图添加图标添加点击事件
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(parseFloat(item.latitude), parseFloat(item.longitude)),
              icon: icon,
              map: null,
              title: item.stationCode,
            });
            // marker.addListener('click', function(){
            //   that.getScooterdetailInfo(marker.getTitle())
            // })
            markersStation.push(marker)
          });
          markerCluster.addMarkers(markersStation, true);
          // this.markersStation = markersStation;
        }
      });
    },
    dateAct() {
      var iconDown = document.getElementById("iconDown");
      var iconUp = document.getElementById("iconUp");
      var dateToggle = document.getElementById("dateToggle");
      var select = document.getElementById("select");
      // if(this.dateShow){
      //   iconDown.style.display = 'none'
      //   iconUp.style.display = 'block'
      //   dateToggle.style.display = 'none'
      // }else{
      //   iconDown.style.display = 'block'
      //   iconUp.style.display = 'none'
      //   dateToggle.style.display = 'block'
      //   dateToggle.appendChild(select)
      // }
      this.dateShow = !this.dateShow;
    },
    async getShooterData(part) {
      let heatmapProviderData = [];
      let that = this;
      await getShooter({
        part: part,
      }).then((res) => {
        if (res.errcode == 202) {
          return;
        }
        let list = res.data.list;
        // let list = {
        //   lat: "106.965913",
        //   lng: "-6.252398"
        // };
        // list.forEach((element) => {
        //   element.forEach((route) => {
        //     if (route && route.gps) {
        //       let gps = route.gps.split(",");
        //       heatmapProviderData.push({ lat: gps[0], lng: gps[1] });
        //     }
        //   });
        // });
        // heatmapProvider.addData(this.heatmapProviderData, true);
        this.heatmapProviderData =
          this.heatmapProviderData.concat(list);
      });
    },
    async getRouteData(part) {
      let heatmapProviderData = [];
      let that = this;
      await getRoute({
        part: part,
      }).then((res) => {
        if (res.errcode == 202) {
          return;
        }
        let list = res.data.list;
        console.log(res)
        // let list = {
        //   lat: "106.965913",
        //   lng: "-6.252398"
        // };
        // list.forEach((element) => {
        //   element.forEach((route) => {
        //     if (route && route.gps) {
        //       let gps = route.gps.split(",");
        //       heatmapProviderData.push({ lat: gps[0], lng: gps[1] });
        //     }
        //   });
        // });
        // heatmapProvider.addData(this.heatmapProviderData, true);
        this.heatmapProviderData = this.heatmapProviderData.concat(list);
        this.setGoogleData(this.heatmapProviderData);
      });
    },
    // onMarkerClick(e) {
    //   // Get position of the "clicked" marker
    //   // var position = e.target.getGeometry(),
    //   //     // Get the data associated with that marker
    //   //     data = e.target.getData(),
    //   //     // Merge default template with the data and get HTML
    //   //     bubbleContent = this.getBubbleContent(data),
    //   //     bubble = onMarkerClick.bubble;

    //   // // For all markers create only one bubble, if not created yet
    //   // if (!bubble) {
    //   //     bubble = new H.ui.InfoBubble(position, {
    //   //     content: bubbleContent
    //   //     });
    //   //     ui.addBubble(bubble);
    //   //     // Cache the bubble object
    //   //     onMarkerClick.bubble = bubble;
    //   // } else {
    //   //     // Reuse existing bubble object
    //   //     bubble.setPosition(position);
    //   //     bubble.setContent(bubbleContent);
    //   //     bubble.open();
    //   // }

    //   // // Move map's center to a clicked marker
    //   // this.map.setCenter(position, true);
    //   console.log("aaaa");
    // },
    // getBubbleContent(data) {
    //   return [
    //     '<div class="bubble">',
    //     "<span>",
    //     '<a class="bubble-footer" href="//commons.wikimedia.org/" target="_blank">',
    //     '<img class="bubble-logo" src="data/wikimedia-logo.png" width="20" height="20" />',
    //     '<span class="bubble-desc">',
    //     "Photos provided by Wikimedia Commons are <br/>under the copyright of their owners.",
    //     "</span>",
    //     "</a>",
    //     "</span>",
    //     "</div>",
    //   ].join("");
    // },
    //here地图信息框
    // mapsFullScreenControl(ui) {
    //   let that = this;
    //   var inherits = function (childCtor, parentCtor) {
    //     function tempCtor() { }
    //     tempCtor.prototype = parentCtor.prototype;
    //     childCtor.superClass_ = parentCtor.prototype;
    //     childCtor.prototype = new tempCtor();
    //     childCtor.prototype.constructor = childCtor;
    //     childCtor.base = function (me, methodName, var_args) {
    //       var args = new Array(arguments.length - 2);
    //       for (var i = 2; arguments.length; i++) {
    //         args[i - 2] = arguments[i];
    //       }
    //       return parentCtor.prototype[methodName].apply(me, args);
    //     };
    //   };
    //   var customUI = function (opt_options) {
    //     "use strict";
    //     var options = opt_options || {};
    //     H.ui.Control.call(this);
    //     this.onButtonClick = this.onButtonClick.bind(this);
    //     // this.increaseBtn_ = new H.ui.base.PushButton({
    //     //   label:
    //     //     // '<img style="margin-top: 4px" src="https://app.swap.id/file/20215910125925.png" alt="">',
    //     //     toggle,
    //     //   onStateChange: this.onButtonClick,
    //     // });
    //     // //add the buttons as this control's children
    //     // this.addChild(this.increaseBtn_);
    //     this.setAlignment(options["alignment"] || "bottom-right");
    //     this.options_ = options;
    //   };
    //   inherits(customUI, H.ui.Control);
    //   customUI.prototype.onButtonClick = function (evt) {
    //     "use strict";
    //     if (evt.currentTarget.getState() === "down") {
    //       that.show = !that.show;
    //     } else {
    //       that.show = !that.show;
    //     }
    //   };
    //   var WaliedCheetos_CustomUI = new customUI();
    //   WaliedCheetos_CustomUI.addClass("customControl");
    //   ui.addControl("WaliedCheetos_CustomUI", WaliedCheetos_CustomUI);
    // },
    // mapHeat(){
    // 	var points=[
    // 	{"lng":106.82721,"lat":-6.17576},
    // 	{"lng":116.423332,"lat":39.916532},
    // 	{"lng":116.419787,"lat":39.930658},
    // 	{"lng":116.418455,"lat":39.920921},
    // 	{"lng":116.418843,"lat":39.915516},
    // 	{"lng":116.42546,"lat":39.918503},
    // 	{"lng":116.423289,"lat":39.919989},
    // 	{"lng":116.418162,"lat":39.915051},
    // 	{"lng":116.422039,"lat":39.91782},
    // 	{"lng":116.41387,"lat":39.917253}];
    // 	// /* 热力图 */
    // 	var heatmapData = [];
    // 	for (var i = 0; i < points.length; i++) {
    // 		heatmapData.push(new google.maps.LatLng(points[i].lat, points[i].lng));
    // 	}
    // 	const heatmap = new google.maps.visualization.HeatmapLayer({
    // 		data: heatmapData,
    // 		// gradient:[
    // 		// 	'rgba(204, 1, 3, 0)',
    // 		// 	'rgba(0, 255, 255, 1)',
    // 		// 	'rgba(0, 191, 255, 1)',
    // 		// 	'rgba(0, 127, 255, 1)',
    // 		// 	'rgba(0, 63, 255,1)',
    // 		// 	'rgba(0, 0, 255, 1)',
    // 		// 	'rgba(0, 0, 223, 1)',
    // 		// 	'rgba(0, 0, 191,1)',
    // 		// 	'rgba(0, 0, 159, 1)',
    // 		// 	'rgba(0, 0, 127, 1)',
    // 		// 	'rgba(63, 0, 91,1)',
    // 		//  	'rgba(127, 0, 63, 1)',
    // 		// 	'rgba(191, 0, 31, 1)',
    // 		// 	'rgba(248,6,8,1)'
    // 		// ]
    // 	});
    // 	heatmap.setMap(this.map);
    // 	this.map.panTo(new google.maps.LatLng(points[0].lat, points[0].lng));
    // },
    // 返回原来位置
    setCenter() {
      const centerid = document.getElementById("centerid");
      const chicago = { lat: -6.17576, lng: 106.82721 };
      centerid.addEventListener("click", () => {
        this.map.setCenter(chicago);
      });
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        centerid
      );
    },
    // sethotData(){
    // 	var heatMapData = [
    // 		{location: new google.maps.LatLng(37.782, -122.447), weight: 0.5},
    // 		new google.maps.LatLng(37.782, -122.445),
    // 		{location: new google.maps.LatLng(37.782, -122.443), weight: 2},
    // 		{location: new google.maps.LatLng(37.782, -122.441), weight: 3},
    // 		{location: new google.maps.LatLng(37.782, -122.439), weight: 2},
    // 		new google.maps.LatLng(37.782, -122.437),
    // 		{location: new google.maps.LatLng(37.782, -122.435), weight: 0.5},

    // 		{location: new google.maps.LatLng(37.785, -122.447), weight: 3},
    // 		{location: new google.maps.LatLng(37.785, -122.445), weight: 2},
    // 		new google.maps.LatLng(37.785, -122.443),
    // 		{location: new google.maps.LatLng(37.785, -122.441), weight: 0.5},
    // 		new google.maps.LatLng(37.785, -122.439),
    // 		{location: new google.maps.LatLng(37.785, -122.437), weight: 2},
    // 		{location: new google.maps.LatLng(37.785, -122.435), weight: 3}
    // 	];
    // 	var heatmap = new google.maps.visualization.HeatmapLayer({
    // 	data: heatMapData,
    // 					gradient:[
    // 						"rgba(0, 255, 255, 0)",
    // 		"rgba(0, 255, 255, 1)",
    // 		"rgba(0, 191, 255, 1)",
    // 		// "rgba(0, 127, 255, 1)",
    // 		// "rgba(0, 63, 255, 1)",
    // 		// "rgba(0, 0, 255, 1)",
    // 		// "rgba(0, 0, 223, 1)",
    // 		"rgba(0, 0, 191, 1)",
    // 		"rgba(0, 0, 159, 1)",
    // 		// "rgba(0, 0, 127, 1)",
    // 		// "rgba(63, 0, 91, 1)",
    // 		// "rgba(127, 0, 63, 1)",
    // 		"rgba(191, 0, 31, 1)",
    // 		"rgba(255, 0, 0, 1)",
    // 					]
    // 	});
    // 	heatmap.set("radius", heatmap.get("radius") ? null : 10);
    // 	heatmap.setMap(this.map);
    // },
    //地图
    setGoogleData(data) {
      console.log(data)
      let _that = this;
      var centerPoint = new google.maps.LatLng(-6.17576, 106.82721);
      this.map = new google.maps.Map(document.getElementById('googlemap'), {
        zoom: 15,
        minZoom: 1,
        center: centerPoint,
        // styles : styleJson,
        //zoomControl: false, // 启用/禁用缩放控件
        mapTypeControl: false, // 启用/禁用允许用户在地图类型（例如地图和卫星）之间切换的地图类型控件
        scaleControl: false, // 启用/禁用提供简单地图比例的“缩放”控件
        streetViewControl: false, // 启用/禁用Pegman控件
        //rotateControl: false, // 启用/禁用旋转控件的外观以控制45°图像的方向
        fullscreenControl: true, // 是否全屏 此控件在移动设备上可见，在桌面上不可见
        gestureHandling: 'greedy', // 属性设置
        // greedy为触摸屏和移动设备，以允许用户在用户滑动（拖动）屏幕时平移地图（向上或向下，向左或向右）。换句话说，单指滑动和双指滑动都可以使地图平移。
      });
      this.map.addListener('click', function () {
        _that.info = undefined;
        if (_that.marker) {
          _that.marker.setIcon({
            url: 'https://i.postimg.cc/KvBXBkmB/3-63.png',
            scaledSize: new google.maps.Size(27, 49),
          })
          _that.marker = undefined;
        }
      })
      const heatmap = new google.maps.visualization.HeatmapLayer({
        data: _that.getPoints(data),
        // gradient:
        // [
        //   "rgba(183, 240, 255, 0.2)",
        //   "rgba(138, 223, 255, 1)",
        //   "rgba(88, 203, 255, 1)",
        //   "rgba(10, 174, 255, 1)",
        //   "rgba(0, 195,178, 1)",
        //   "rgba(0, 227, 83, 1)",
        //   "rgba(12, 255, 0, 1)",
        //   "rgba(83, 255, 0, 1)",
        //   "rgba(141, 255, 0, 1)",
        //   "rgba(229, 255, 0, 1)",
        //   "rgba(255, 233, 0, 1)",
        //   "rgba(255, 148,0, 1)",
        //   "rgba(255, 80, 0, 1)",
        //   "rgba(255, 38, 0, 1)",
        //   "rgba(255, 2, 0, 1)",
        // ],
        map: this.map,
        // opacity: 1,
        dissipating: true,
        maxIntensity: 5
      });
      this.heatmap = heatmap
      heatmap.set("radius", 15);
      const rightLegend = document.getElementById("rightLegend");
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        rightLegend
      );
      this.getMarkerDate()
    },
    getPoints(data) {
      let heatmapData = []
      data.forEach((item, key) => {
        // console.log(item,key)
        if (item) {
          heatmapData.push({ location: new google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)), weight: item.num })
          // heatmapData.push(new google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)))
        }
      })
      // console.log(heatmapData)
      return heatmapData
      // return [
      //   new google.maps.LatLng(-6.247441, 106.928993),
      //   new google.maps.LatLng(37.782745, -122.444586),
      //   new google.maps.LatLng(37.782842, -122.443688),
      //   new google.maps.LatLng(37.782919, -122.442815),
      // ];
    },
    async getData() {
      const obj = {
        lang: this.value,
        page: this.page,
        size: this.pageSize,
        token: this.token,
        //token:''
      };
      const data = await getNewsData(obj);
      if (data.code === 200) {
        const dataNews = data.data;
        this.dataAll = dataNews.count;
        this.tableData = dataNews.list;
        if (this.dataAll % this.pageSize === 0) {
          this.currentPage4 = this.dataAll / this.pageSize + 1;
        } else {
          this.currentPage4 = this.dataAll / this.pageSize;
        }
      } else {
        this.tixi(data.message);
      }
    },
    tixi(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    onToggle(actColor) {
      let that = this;
      this.show = true;
      this.scooterRankValue = false
      if (this.actColor == actColor) {
        return
      }
      if (this.marker) {
        this.marker.setIcon({
          url: 'https://i.postimg.cc/KvBXBkmB/3-63.png',
          scaledSize: new google.maps.Size(27, 49),
        })
        this.info = undefined
        this.marker = undefined;
      }
      this.actColor = actColor
      if (this.actColor == 0) {
        // this.heatmap.setMap(null)
        // this.markersScooter.forEach(v=>{
        //   v.setMap(null)
        // })
        // this.markersStation.forEach(v=>{
        //   v.setMap(this.map)
        // })
      } else if (this.actColor == 1) {
        this.getRouteData(1)
        // this.heatmap.setMap(null)
        // this.markersScooter.forEach(v=>{
        //   v.setMap(null)
        // })
        // this.markersStation.forEach(v=>{
        //   v.setMap(null)
        // })
        // this.heatmap.setMap(this.map)
      } else if (this.actColor == 3) {
        // this.heatmap.setMap(null)
        // this.markersStation.forEach(v=>{
        //   v.setMap(null)
        // })
        // this.markersScooter.forEach(v=>{
        //   v.setMap(this.map)
        // })
      } else if (this.actColor == 2) {
        // this.show=false
      }
    },
    startClustering(map, data) {
      var dataPoints = data.map(function (item) {
        return new H.clustering.DataPoint(
          item.latitude,
          item.longitude,
          null,
          item
        );
      });
      var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
        clusteringOptions: {
          // Maximum radius of the neighbourhood
          // eps: 32,
          // minimum weight of points required to form a cluster
          minWeight: 2,
          // max: 8,
        },
        // max: 12,
      });
      // var that = this
      clusteredDataProvider.addEventListener("tap", this.onMarkerClick);
      // clusteredDataProvider.addEventListener('tap', that.onMarkerClick(Event));
      // clusteredDataProvider.addEventListener('tap', function(evt, data){
      //   console.log(evt)
      //   that.onMarkerClick(evt)
      // });
      var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
      return clusteringLayer;
    },
    onMarkerClick(e) {
      if (!this.flag) {
        return;
      }
      this.flag = 0
      let that = this;
      if (this.marker) {
        this.map.removeObject(this.marker);
        this.marker = undefined;
      }
      if (e.target.getData()) {
        var position = e.target.getGeometry(),
          codeData = e.target.getData().a
            ? e.target.getData().a.data
            : e.target.getData();
      } else {
        this.flag = 1
        return;
      }
      if (codeData && codeData.scooterCode) {
        getScooterdetail({
          scooterCode: codeData.scooterCode,
        }).then((res) => {
          var data = res.data.scooter;
          var geocoder = this.platform.getSearchService(),
            reverseGeocodingParameters = {
              at: codeData.lat + "," + codeData.lng + ",150", // Berlin
              limit: "1",
            };
          geocoder.reverseGeocode(
            reverseGeocodingParameters,
            function (result) {
              console.log(data);
              var address = result.items[0].address;
              data.address = address.label;
              that.info = data;
              console.log(that.info);
            },
            that.onError
          );
          that.map.setCenter({ lat: codeData.lat, lng: codeData.lng });
          this.map.setZoom("15");
          this.marker = new H.map.Marker(
            { lat: codeData.lat, lng: codeData.lng, size: { w: 36, h: 65 }, },
            // { min: 12 }
          );
          this.map.addObject(this.marker);
          this.flag = 1
        }).catch(err => {
          this.flag = 1
          console.log(err)
        });
      }
    },
    make_map(cityname, dom_id, list, max) {
      //获取地图json创建图表
      const that = this;
      // let geojson_multiPolygon = {
      //   type: 'Feature',
      //   properties: {},
      //   geometry: {
      //     type: 'MultiPolygon',
      //     coordinates: [
      //       [
      //         [
      //           [109.2041015625, 30.088107753367257],
      //           [115.02685546875, 30.088107753367257],
      //           [115.02685546875, 32.7872745269555],
      //           [109.2041015625, 32.7872745269555],
      //           [109.2041015625, 30.088107753367257]
      //         ]
      //       ],
      //       [
      //         [
      //           [112.9833984375, 26.82407078047018],
      //           [116.69677734375, 26.82407078047018],
      //           [116.69677734375, 29.036960648558267],
      //           [112.9833984375, 29.036960648558267],
      //           [112.9833984375, 26.82407078047018]
      //         ]
      //       ]
      //     ]
      //   }
      // };
      // const fs = require('fs');
      // console.log(fs)
      // let a = geobuf.encode(geojson_multiPolygon, new Pbf());
      // console.log('序列化:', a);
      // fs.writeFile('./data/lamp.geobuf.bpf', a, function (error) {
      //   if (error) {
      //     console.info('geobuf error')
      //   } else {
      //     console.info('geobuf ok')
      //   }
      // })
      // let b = geobuf.decode(new Pbf(a));
      // console.log('反序列化:', b);
      var achart = this.$echarts.init(document.getElementById(dom_id));
      // $.get('./Indo_Districts.geojson', function (usaJson) {
      // myChart.hideLoading();
      that.$echarts.registerMap('印度尼西亚, 印尼', that.indoJson);
      var option = {
        title: {
        },
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
        },
        visualMap: {
          // left: "right",
          min: 0,
          max: max,
          inRange: {
            color: [
              // "#313695",
              // "#4575b4",
              // "#74add1",
              // "#abd9e9",
              // "#e0f3f8",
              "#ffffbf",
              "#fee090",
              "#fdae61",
              "#f46d43",
              "#d73027",
              "#a50026",
            ],
          },
          text: ["High", "Low"],
          calculable: true,
          textStyle: {
            color: "#fff"
          }
        },
        toolbox: {
          show: true,
          //orient: 'vertical',
          // left: "left",
          // left: 'right',
          top: "top",
          // feature: {
          //   dataView: { readOnly: false },
          //   restore: {},
          //   saveAsImage: {}
          // }
        },
        series: [
          {
            zoom: 3,
            mapType: cityname,
            name: "Indonesia",
            center: [110.346680, -4.499762],
            type: "map",
            roam: true,
            // map: 'USA',
            emphasis: {
              label: {
                show: true,
              },
            },
            label: {
              // show: true,
            },
            data: list,
          },
        ],
      };
      achart.setOption(option);
      // })
    },
    handleClick(tab, event) {
      this.onToggle(this.activeName);
    }
  },
  filter: {},
};
</script>
<style>
.title {
  font-size: 26px;
  margin: 10px;
  font-weight: 700;
  background-image: -webkit-linear-gradient(bottom, #5197ff, #80f2ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content1 {
  display: flex;
  color: #fff;
  justify-content: space-between;
  margin: auto 1rem;
}
.content2 {
  display: flex;
  margin-top: 2rem;
}
.content2-right {
  width: 22rem;
  height: 17rem;
  border: 0.15rem solid #0089ff;
  padding: 1.2rem;
}
.content3-title {
  text-align: center;
  color: #fff;
  background-color: #0089ff;
  width: 9rem;
  height: 2rem;
  margin: auto;
  line-height: 2rem;
  margin-top: -1rem;
}
.content3-items {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}
.content3-item {
  color: #fff;
  height: 2rem;
  text-align: center;
  margin: 0 1rem;
}
.content3-item-name {
  color: #9feaf8;
}
.content3-item-number {
  margin-top: 1rem;
}
.content3-item-number span {
  color: #0191a9;
  padding: 0.5rem 0.3rem;
  background-color: #092126;
  margin: 0 0.05rem;
}
.content4-item {
  width: 25rem;
  height: 25rem;
}
.one-add1 {
  width: 60rem;
  height: 20rem;
}
.one-add2 {
  width: 30rem;
  height: 20rem;
}
.el-range-editor .el-range-input {
  background-color: #021418;
  color: #026476;
}
.guiji {
  display: flex;
  height: calc(100vh - 0.25rem - 65px);
  position: relative;
  padding: 0 20px;
}
.guiji-right {
  width: 41%;
  padding: 0 20px;
  color: #fff;
  background-color: #061854;
  position: relative;
  font-size: 0.07rem;
  overflow-x: hidden;
  overflow-y: auto;
  overflow: hidden;
}
.inner-container {
  position: absolute;
  left: 0;
  top: 0;
  right: -17px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 20px;
}
.boxfoot {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.boxfoot:before,
.boxfoot:after {
  position: absolute;
  width: 0.06rem;
  height: 0.06rem;
  content: "";
  border-bottom: 2px solid #02a6b5;
  bottom: 0;
}
.guiji-right-top {
  padding: 0.05rem;
  margin-bottom: 10px;
}
.guiji-right-item {
  width: 0.4rem;
  padding: 0.05rem;
  text-align: center;
}
.guiji-right-item span {
  /* background-color: #0089ff; */
  padding: 0.02rem;
}
.guiji-right-content {
  display: flex;
}
.guiji-right-location {
  flex: 1;
  text-align: center;
  padding: 0.05rem;
}
.content5 {
  display: flex;
  margin: auto 1rem;
  margin-top: 2rem;
}
.el-select .el-input {
  width: 110px;
}
.input-with-select .el-input-group__prepend {
  background-color: #0d3167;
}
x
.active {
  display: none;
}
#contentDitor img {
  max-width: 450px;
}
@media screen and (max-width: 767px) {
  #contentDitor img {
    max-width: 226px;
  }
  .el-form-item__label {
    text-align: none;
  }
  .el-pager {
    display: none;
  }
  .el-pagination .el-select .el-input {
    margin: 0;
  }
}
.div_any_child01 {
  width: 48%;
  box-shadow: -10px 0px 15px #034c6a inset,
    0px -10px 15px #034c6a inset,
    10px 0px 15px #034c6a inset,
    0px 10px 15px #034c6a inset;
  border: 1px solid #034c6a;
  box-sizing: border-box;
  position: relative;
  margin-right: 2%;
}
.div_any_child {
  width: 100%;
  height: 280px;
  box-sizing: border-box;
  position: relative;
  margin-top: 25px;
}
.div_any_child01_wh {
  width: 98% !important;
}
.div_height01 {
  height: auto !important;
  padding: 5px;
}
.char_table {
  height: 200px;
}
.p_chart {
  height: 250px;
  padding: 5px 10px;
  margin-top: 15px;
}
.table_p {
  height: 93%;
  margin-top: 7%;
  position: relative;
}
.table_p01 {
  height: auto !important;
  margin-top: 0 !important;
  position: relative;
}
.table_p01 table td {
  padding: 6px 0;
}
.table_p table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  position: absolute;
  text-align: center;
}
.table_p table thead th {
  color: #61d2f7;
  font-size: 14px;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
}
.table_p table tbody {
  color: #ffffff;
  font-size: 13px;
}
.table_p table tbody tr:nth-child(2n + 1) {
  background-color: #072951;
  box-shadow: -10px 0px 15px #034c6a inset, 10px 0px 15px #034c6a inset;
}
.color1 {
  color: #2dd660;
}
.color2 {
  color: #f58038;
}
.color3 {
  color: #0563eb;
}
.customControl {
  text-align: center;
}
.date {
  position: absolute;
  top: 35px;
  left: 35px;
  background: #171f4f;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  color: #ffffff;
  padding: 7px 20px;
}
.date2 {
  position: absolute;
  top: 35px;
  right: 31%;
  box-shadow: 0em 0 0.4em 0 rgb(15 22 33 / 60%);
  border-radius: 0.5em;
  width: 40px;
  height: 40px;
  background: #fff;
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  color: #ffffff;
}
.date1 {
  position: absolute;
  bottom: 35px;
  right: 32%;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  color: #ffffff;
  box-shadow: 0em 0 0.4em 0 rgb(15 22 33 / 60%);
  border-radius: 0.5em;
  width: 40px;
  height: 40px;
  background: #fff;
  z-index: 100;
  text-align: center;
  line-height: 40px;
}
.log-entry {
  padding: 5px;
  border-bottom: 1px solid #d0d9e9;
}
.log-entry:nth-child(odd) {
  background-color: #e1e7f1;
}
.select {
  margin-top: 10px;
}
.citymap {
  width: 100%;
  height: 100%;
}
.title-item {
  width: 100%;
  font-size: 12px;
  color: white;
  display: flex;
  margin-top: 0.25rem;
  justify-content: flex-end;
}
.title-item div {
  padding: 0 30px;
}
.act-color {
  color: red;
}
.el-tabs__nav-scroll {
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}
.el-tabs__nav-wrap::after {
  background-color: inherit;
}
.el-tabs__item {
  color: #c4d1e8;
}
.rlt-title .el-tabs__item {
  height: 40px;
  line-height: 40px;
}
.log {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 285px;
  background: white;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
}
.log-entry {
  padding: 5px;
  border-bottom: 1px solid #d0d9e9;
}
.log-entry:nth-child(odd) {
  background-color: #e1e7f1;
}
.date {
  position: absolute;
  top: -5px;
  left: 35px;
  margin: 0;
  font-size: 12px;
  z-index: 200;
}
.info-personal {
  display: flex;
  padding: 15px 20px;
  align-items: center;
}
.info-personal img {
  width: 52px;
  height: 52px;
  border-radius: 52px;
}
.info-personal div {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
.info-personal div :nth-child(1) {
  color: #333333;
  font-weight: 700;
  font-size: 14px;
}
.info-personal div :nth-child(2) {
  color: #333333;
  font-size: 10px;
}
.zaixian {
  color: white;
  background-color: #2dd660;
  font-size: 10px;
  width: 40px;
  text-align: center;
}
.lixian {
  color: white;
  background-color: red;
  font-size: 10px;
  width: 40px;
  text-align: center;
}
.info-motorcycle {
  display: flex;
  justify-content: space-between;
  margin: 15px 20px 0 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  color: #333333;
}
.info-motorcycle span {
  width: 150px;
  text-align: right;
}
.info-motorcycle img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.info-motorcycle div:nth-child(1) {
  display: flex;
  align-content: center;
  align-items: center;
}
.el-switch__label {
  color: #fff;
}</style>
<style scoped>.el-range-editor.el-input__inner {
  background-color: #021418;
}
.el-input__inner {
  border: 1px solid #66beff;
}
.el-date-editor .el-range-separator {
  color: #66beff;
}
.el-input__inner {
  border: 1px solid #0d3167;
  background: #0d3167;
}</style>
